<template>
  <div>
    <form v-on:submit.prevent="addCampaignList">
      <!-- Email campaign -->
      <div class="campaign-top">
        <div class="back-lead">
          <a href="/campaigns-list"
            ><img
              class="img-fluid arrow"
              src="../../assets/img/27. Left.svg"
              alt=""
            />
            Back
          </a>
        </div>
        <div class="preview-generator">
          <p>
            Preview
            <a
              class="previews"
              href="#"
              data-toggle="modal"
              data-target="#exampleModal33"
              @click="get_campaign_list_users()"
              ><img class="img-fluid" src="../../assets/img/eye1.svg" alt=""
            /></a>
            <button :disabled="btnLoader" type="submit" class="rem-btn">
              <span v-if="btnLoader"
                ><span class="spinner-grow spinner-grow-sm"></span>
                Loading...</span
              >
              <span v-if="!btnLoader"> Save</span>
            </button>
          </p>
        </div>
      </div>
      <div class="email-campaign">
        <div class="step1-area">
          <div class="email-deatils">
            <h3 v-if="cl_.user_cate == 'customers'">Customer</h3>
            <h3 v-if="cl_.user_cate == 'employees'">Employees</h3>

            <div class="form-row">
              <div class="form-group col-12">
                <label>Title</label>
                <input type="text" class="form-control" v-model="cl_.title" />
                <div
                  class="validate-error-message"
                  v-if="$vd.cl_.title.$hasError === true"
                >
                  {{ $vd.cl_.$errors.title[0] }}
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-6 col-md-6">
                <label>Users </label>
                <label class="con-company currnt">
                  Customers<input
                    type="radio"
                    name="user_cate"
                    value="customers"
                    v-model="cl_.user_cate"
                    v-on:change="selectedFilter($event, 'user_cate')"
                  />
                  <span class="checkmark"></span>
                </label>
              </div>
              <div class="form-group col-sm-6 col-md-6">
                <label> </label>
                <label class="con-company">
                  Employees
                  <input
                    type="radio"
                    name="user_cate"
                    value="employees"
                    v-model="cl_.user_cate"
                    v-on:change="selectedFilter($event, 'user_cate')"
                  />
                  <span class="checkmark"></span>
                </label>
              </div>
              <div
                class="validate-error-message"
                v-if="$vd.cl_.user_cate.$hasError === true"
              >
                {{ $vd.cl_.$errors.user_cate[0] }}
              </div>
            </div>
          </div>

          <div class="email-deatils" v-if="cl_.user_cate == 'customers'">
            <!-- <div class="form-row">
                        <div class="form-group col-sm-6 col-md-6 col-lg-4">
                            <label>Type </label>
                            <label class="con-company currnt">
                                Individual<input type="radio" checked="checked" name="radio" />
                                <span class="checkmark"></span>
                            </label>
                            <a class="subtrack" href="#"><img class="img-fluid" src="../../assets/img/Subtract.svg" alt="" /></a>
                        </div>
                        <div class="form-group col-sm-6 col-md-6 col-lg-4">
                            <label> </label>
                            <label class="con-company">
                                Company
                                <input type="radio" name="radio" />
                                <span class="checkmark"></span>
                            </label>
                            <a class="subtrack" href="#"><img class="img-fluid" src="../../assets/img/Subtract.svg" alt="" /></a>
                        </div>
                        <div class="form-group col-sm-6 col-md-6 col-lg-4">
                            <label> </label>
                            <label class="con-company">
                                All
                                <input type="radio" name="radio" />
                                <span class="checkmark"></span>
                            </label>
                            <a class="subtrack" href="#"><img class="img-fluid" src="../../assets/img/Subtract.svg" alt="" /></a>
                        </div>
                    </div> -->

            <!-- Time Range -->
            <div class="form-row">
              <div class="form-group col-sm-6 col-md-6 col-lg-4">
                <label>Time created</label>
                <label class="con-company currnt">
                  All time<input
                    type="radio"
                    v-on:change="selectedFilter($event, 'time_created')"
                    value="all"
                    v-model="cl_.duration"
                    name="time_created"
                  />
                  <span class="checkmark"></span>
                </label>
                <a class="subtrack" href="#"
                  ><img
                    class="img-fluid"
                    src="../../assets/img/Subtract.svg"
                    alt=""
                /></a>
              </div>
              <div class="form-group col-sm-6 col-md-6 col-lg-4">
                <label> </label>
                <label class="con-company">
                  Last Year
                  <input
                    type="radio"
                    value="year"
                    v-on:change="selectedFilter($event, 'time_created')"
                    v-model="cl_.duration"
                    name="time_created"
                  />
                  <span class="checkmark"></span>
                </label>
                <a class="subtrack" href="#"
                  ><img
                    class="img-fluid"
                    src="../../assets/img/Subtract.svg"
                    alt=""
                /></a>
              </div>
              <div
                class="form-group col-sm-6 col-md-6 col-lg-4"
                v-show="cl_.duration != 'custom'"
              >
                <label> </label>
                <label class="con-company">
                  Custom Range
                  <input
                    type="radio"
                    value="custom"
                    v-on:change="selectedFilter($event, 'time_created')"
                    v-model="cl_.duration"
                    name="time_created"
                  />
                  <span class="checkmark"></span>
                </label>
                <a class="subtrack" href="#"
                  ><img
                    class="img-fluid"
                    src="../../assets/img/Subtract.svg"
                    alt=""
                /></a>
              </div>
              <div
                class="form-group col-sm-6 col-md-6 col-lg-4 campignlist"
                v-show="cl_.duration == 'custom'"
              >
                <label>Choose Date</label>
                <date-range-picker
                  :opens="'left'"
                  :ranges="false"
                  :locale-data="{ format: 'yyyy-mm-dd' }"
                  v-model="cl_.customDate"
                  @update="selectedFilter($event, 'time_created')"
                ></date-range-picker>

                <!-- @update="funnelReport('custom')" -->
                <!-- <input type="text" class="form-control custom-ranges" name="datefilter" value="" placeholder="Custom Range" /> -->
                <img
                  class="img-fluid custor-sericon"
                  src="../../assets/img/9. Calendar.svg"
                  alt=""
                />
              </div>
            </div>

            <!-- Additional Filters -->
            <div class="form-row">
              <div class="form-group col-sm-6 col-md-6 col-lg-4">
                <label>Additional Filters</label>
                <label class="con-company">
                  Located in specific area.
                  <input
                    type="radio"
                    v-on:change="selectedFilter($event, 'filter_type')"
                    value="by_area"
                    v-model="cl_.filter_type"
                    name="filter_type"
                  />
                  <span class="checkmark"></span>
                </label>
                <a class="subtrack" href="#"
                  ><img
                    class="img-fluid"
                    src="../../assets/img/Subtract.svg"
                    alt=""
                /></a>
              </div>
              <div class="form-group col-sm-6 col-md-6 col-lg-4">
                <label> </label>
                <label class="con-company">
                  Interested in specific vehicle.
                  <input
                    type="radio"
                    value="by_specific_vehicle"
                    v-on:change="selectedFilter($event, 'filter_type')"
                    v-model="cl_.filter_type"
                    name="filter_type"
                  />
                  <span class="checkmark"></span>
                </label>
                <a class="subtrack" href="#"
                  ><img
                    class="img-fluid"
                    src="../../assets/img/Subtract.svg"
                    alt=""
                /></a>
              </div>
              <div class="form-group col-sm-6 col-md-6 col-lg-4">
                <label> </label>
                <label class="con-company">
                  By specific Leadsheets.
                  <input
                    type="radio"
                    value="by_source"
                    v-on:change="selectedFilter($event, 'filter_type')"
                    v-model="cl_.filter_type"
                    name="filter_type"
                  />
                  <span class="checkmark"></span>
                </label>
                <a class="subtrack" href="#"
                  ><img
                    class="img-fluid"
                    src="../../assets/img/Subtract.svg"
                    alt=""
                /></a>
              </div>
            </div>

            <!-- By Specific Area -->
            <div class="form-row" v-show="cl_.filter_type == 'by_area'">
              <div class="form-group col-sm-6 col-md-6 col-lg-4">
                <label> Address</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="City"
                  v-model="cl_.city"
                />
              </div>
              <div class="form-group col-sm-6 col-md-6 col-lg-4">
                <label> </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="State"
                  v-model="cl_.state"
                />
              </div>
              <div class="form-group col-sm-6 col-md-6 col-lg-4">
                <label> </label>
                <multi-select
                  :searchable="true"
                  id="a_zip"
                  textColumn="text"
                  placeholder="Zip Code"
                  v-model="cl_.zip"
                  :options="allZipCodes"
                  label=""
                ></multi-select>

                <!-- <label> </label>
                            <select id="select" class="form-control" multiple="multiple" aria-placeholder="Zip">
                                <option selected="selected">Zip Code</option>
                                <option>1250</option>
                                <option>4522</option>
                                <option>1235</option>
                                <option>1024</option>
                            </select> -->
              </div>
            </div>

            <!-- <div class="form-row">
                        <div class="form-group col-sm-6 col-md-4 col-lg-3">
                            <div class="deal-type auto-chk">
                                <h3>
                                    <span class="auto-cc">
                                        Email Address
                                        <label class="switch">
                                            <input type="checkbox" checked="" />
                                            <span class="slider round"></span>
                                        </label>
                                    </span>
                                </h3>
                            </div>
                        </div>
                        <div class="form-group col-sm-6 col-md-4 col-lg-3">
                            <div class="deal-type auto-chk">
                                <h3>
                                    <span class="auto-cc">
                                        Phone
                                        <label class="switch">
                                            <input type="checkbox" checked="" />
                                            <span class="slider round"></span>
                                        </label>
                                    </span>
                                </h3>
                            </div>
                        </div>
                    </div> -->

            <!-- By Lead Sheets -->
            <div class="form-row" v-show="cl_.filter_type == 'by_source'">
              <div class="form-group col-sm-6 col-md-6 col-lg-4">
                <label>Source </label>
                <select class="form-control" v-model="cl_.source">
                  <option value="all" selected>All</option>
                  <option
                    v-for="source in LSources"
                    :value="source.ls_id"
                    :key="source.ls_id"
                  >
                    {{ source.ls_name }}
                  </option>
                </select>
              </div>
              <!-- <div class="form-group col-sm-6 col-md-6 col-lg-4">
                            <label>Source </label>
                            <select class="form-control">
                                <option>Select a property</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                            </select>
                        </div> -->
              <div class="form-group col-sm-6 col-md-6 col-lg-4">
                <label>Status</label>
                <select class="form-control" v-model="cl_.status">
                  <option value="0">All</option>
                  <option value="1">Active</option>
                  <option value="2">Contacted</option>
                  <option value="3">Scheduled</option>
                  <option value="4">Visit</option>
                  <option value="5">Demo</option>
                  <option value="6">Sold</option>
                  <option value="7">Lost</option>
                </select>
              </div>
              <div class="form-group col-sm-6 col-md-6 col-lg-4">
                <label> Assigned to</label>
                <select class="form-control" v-model="cl_.agent">
                  <option value="">Select Agent</option>
                  <option
                    v-for="emp in allEmployees"
                    :key="emp.id"
                    :value="emp.id"
                  >
                    {{ emp.text }}
                  </option>
                </select>
              </div>
            </div>

            <div class="form-row" v-show="cl_.filter_type == 'by_source'">
              <div class="form-group col-sm-6 col-md-6 col-lg-4">
                <label>Lead Sheet Created Date</label>
                <label class="con-company currnt">
                  All time<input
                    type="radio"
                    value="all"
                    v-on:change="
                      selectedFilter($event, 'lead_created_duration')
                    "
                    v-model="cl_.lead_created_duration"
                    name="lead_created_duration"
                  />
                  <span class="checkmark"></span>
                </label>
                <a class="subtrack" href="#"
                  ><img
                    class="img-fluid"
                    src="../../assets/img/Subtract.svg"
                    alt=""
                /></a>
              </div>
              <div class="form-group col-sm-6 col-md-6 col-lg-4">
                <label> </label>
                <label class="con-company">
                  Last Year
                  <input
                    type="radio"
                    value="year"
                    v-on:change="
                      selectedFilter($event, 'lead_created_duration')
                    "
                    v-model="cl_.lead_created_duration"
                    name="lead_created_duration"
                  />
                  <span class="checkmark"></span>
                </label>
                <a class="subtrack" href="#"
                  ><img
                    class="img-fluid"
                    src="../../assets/img/Subtract.svg"
                    alt=""
                /></a>
              </div>
              <div
                class="form-group col-sm-6 col-md-6 col-lg-4"
                v-show="cl_.lead_created_duration != 'custom'"
              >
                <label> </label>
                <label class="con-company">
                  Custom Range
                  <input
                    type="radio"
                    value="custom"
                    v-on:change="
                      selectedFilter($event, 'lead_created_duration')
                    "
                    v-model="cl_.lead_created_duration"
                    name="lead_created_duration"
                  />
                  <span class="checkmark"></span>
                </label>
                <a class="subtrack" href="#"
                  ><img
                    class="img-fluid"
                    src="../../assets/img/Subtract.svg"
                    alt=""
                /></a>
              </div>
              <div
                class="form-group col-sm-6 col-md-6 col-lg-4 campignlist"
                v-show="cl_.lead_created_duration == 'custom'"
              >
                <label>Choose Date</label>
                <date-range-picker
                  :opens="'left'"
                  :ranges="false"
                  :locale-data="{ format: 'yyyy-mm-dd' }"
                  v-model="cl_.lead_created_custom_date"
                  @update="selectedFilter($event, 'lead_created_duration')"
                ></date-range-picker>
                <img
                  class="img-fluid custor-sericon"
                  src="../../assets/img/9. Calendar.svg"
                  alt=""
                />
              </div>
            </div>

            <!-- By Specific Vehicle -->
            <div
              class="form-row"
              v-show="cl_.filter_type == 'by_specific_vehicle'"
            >
              <div class="form-group col-sm-6 col-md-6">
                <label>Make</label>
                <select class="form-control" v-model="cl_.make">
                  <option v-for="(makevehicke, index) in make" :key="index">
                    {{ makevehicke.make }}
                  </option>
                </select>
              </div>
              <div class="form-group col-sm-6 col-md-6">
                <label> Model</label>
                <select class="form-control" v-model="cl_.model">
                  <option v-for="(modelvehicle, index) in model" :key="index">
                    {{ modelvehicle.model }}
                  </option>
                </select>
              </div>
              <div class="col-sm-6 col-md-6 pl-3 pr-2">
                <p>
                  <label>Year</label>
                  <input
                    type="text"
                    :value="cl_.year[0] + ' - ' + cl_.year[1]"
                    id="amount2"
                    readonly
                    style="border: 0"
                  />
                </p>
                <vue-slider
                  v-model="cl_.year"
                  v-bind="yearOptions"
                ></vue-slider>
              </div>
              <div class="col-sm-6 col-md-6 pl-3 pr-2">
                <p>
                  <label>Miles </label>
                  <input
                    type="text"
                    :value="'ML ' + cl_.mileage[0] + ' - ML ' + cl_.mileage[1]"
                    id="amount1"
                    readonly
                    style="border: 0"
                  />
                </p>
                <vue-slider
                  v-model="cl_.mileage"
                  v-bind="mileageOptions"
                ></vue-slider>
              </div>
            </div>

            <div
              class="form-row"
              v-show="cl_.filter_type == 'by_specific_vehicle'"
            >
              <div class="form-group col-sm-6 col-md-6 col-lg-2">
                <label>Sales Type </label>
                <label class="con-company">
                  Lease<input
                    type="radio"
                    v-on:change="selectedFilter($event, 'sales_type')"
                    value="leased"
                    v-model="cl_.sales_type"
                    name="sales_type"
                  />
                  <span class="checkmark"></span>
                </label>
                <a class="subtrack" href="#"
                  ><img
                    class="img-fluid"
                    src="../../assets/img/Subtract.svg"
                    alt=""
                /></a>
              </div>
              <div class="form-group col-sm-6 col-md-6 col-lg-2">
                <label> </label>
                <label class="con-company">
                  Purchase
                  <input
                    type="radio"
                    v-on:change="selectedFilter($event, 'sales_type')"
                    value="purchased"
                    v-model="cl_.sales_type"
                    name="sales_type"
                  />
                  <span class="checkmark"></span>
                </label>
                <a class="subtrack" href="#"
                  ><img
                    class="img-fluid"
                    src="../../assets/img/Subtract.svg"
                    alt=""
                /></a>
              </div>
              <div class="form-group col-sm-6 col-md-6 col-lg-2">
                <label> Condition</label>
                <label class="con-company">
                  New
                  <input
                    type="radio"
                    v-on:change="selectedFilter($event, 'condition')"
                    value="new"
                    v-model="cl_.condition"
                    name="condition"
                  />
                  <span class="checkmark"></span>
                </label>
                <a class="subtrack" href="#"
                  ><img
                    class="img-fluid"
                    src="../../assets/img/Subtract.svg"
                    alt=""
                /></a>
              </div>
              <div class="form-group col-sm-6 col-md-6 col-lg-2">
                <label> </label>
                <label class="con-company">
                  Used
                  <input
                    type="radio"
                    v-on:change="selectedFilter($event, 'condition')"
                    value="used"
                    v-model="cl_.condition"
                    name="condition"
                  />
                  <span class="checkmark"></span>
                </label>
                <a class="subtrack" href="#"
                  ><img
                    class="img-fluid"
                    src="../../assets/img/Subtract.svg"
                    alt=""
                /></a>
              </div>
              <div class="form-group col-sm-6 col-md-6 col-lg-2">
                <label>VA Status</label>
                <label class="con-company">
                  Sold
                  <input
                    type="radio"
                    v-on:change="selectedFilter($event, 'va_status')"
                    value="sold"
                    v-model="cl_.va_status"
                    name="va_status"
                  />
                  <span class="checkmark"></span>
                </label>
                <a class="subtrack" href="#"
                  ><img
                    class="img-fluid"
                    src="../../assets/img/Subtract.svg"
                    alt=""
                /></a>
              </div>
              <div class="form-group col-sm-6 col-md-6 col-lg-2">
                <label> </label>
                <label class="con-company">
                  Interested In
                  <input
                    type="radio"
                    v-on:change="selectedFilter($event, 'va_status')"
                    value="active"
                    v-model="cl_.va_status"
                    name="va_status"
                  />
                  <span class="checkmark"></span>
                </label>
                <a class="subtrack" href="#"
                  ><img
                    class="img-fluid"
                    src="../../assets/img/Subtract.svg"
                    alt=""
                /></a>
              </div>
            </div>

            <div
              class="form-row"
              v-show="
                cl_.filter_type == 'by_specific_vehicle' &&
                cl_.va_status == 'sold'
              "
            >
              <div class="form-group col-sm-6 col-md-6 col-lg-4">
                <label>Purchase Date</label>
                <label class="con-company currnt">
                  All time<input
                    type="radio"
                    value="all"
                    v-on:change="selectedFilter($event, 'purchase_duration')"
                    v-model="cl_.purchase_duration"
                    name="purchase_duration"
                  />
                  <span class="checkmark"></span>
                </label>
                <a class="subtrack" href="#"
                  ><img
                    class="img-fluid"
                    src="../../assets/img/Subtract.svg"
                    alt=""
                /></a>
              </div>
              <div class="form-group col-sm-6 col-md-6 col-lg-4">
                <label> </label>
                <label class="con-company">
                  Last Year
                  <input
                    type="radio"
                    value="year"
                    v-on:change="selectedFilter($event, 'purchase_duration')"
                    v-model="cl_.purchase_duration"
                    name="purchase_duration"
                  />
                  <span class="checkmark"></span>
                </label>
                <a class="subtrack" href="#"
                  ><img
                    class="img-fluid"
                    src="../../assets/img/Subtract.svg"
                    alt=""
                /></a>
              </div>
              <div
                class="form-group col-sm-6 col-md-6 col-lg-4"
                v-show="cl_.purchase_duration != 'custom'"
              >
                <label> </label>
                <label class="con-company">
                  Custom Range
                  <input
                    type="radio"
                    value="custom"
                    v-on:change="selectedFilter($event, 'purchase_duration')"
                    v-model="cl_.purchase_duration"
                    name="purchase_duration"
                  />
                  <span class="checkmark"></span>
                </label>
                <a class="subtrack" href="#"
                  ><img
                    class="img-fluid"
                    src="../../assets/img/Subtract.svg"
                    alt=""
                /></a>
              </div>
              <div
                class="form-group col-sm-6 col-md-6 col-lg-4"
                v-show="cl_.purchase_duration == 'custom'"
              >
                <label>Choose Date</label>
                <date-range-picker
                  :opens="'left'"
                  :ranges="false"
                  :locale-data="{ format: 'yyyy-mm-dd' }"
                  v-model="cl_.purchase_date"
                  @update="selectedFilter($event, 'purchase_duration')"
                ></date-range-picker>
                <img
                  class="img-fluid custor-sericon"
                  src="../../assets/img/9. Calendar.svg"
                  alt=""
                />
              </div>
            </div>

            <!-- <div class="form-row">
              <div class="form-group col-sm-6 col-md-6 col-lg-4">
                <label>Ok To Contact</label>
                <label class="con-company">
                  Ok To Contact
                  <input
                    type="radio"
                    v-on:change="selectedFilter($event, 'filter_type')"
                    value="1"
                    v-model="cl_.contact"
                    name="filter_type"
                  />
                  <span class="checkmark"></span>
                </label>
              </div>
            </div> -->
          </div>

          <!-- <div class="modal-footer campaign-footer">
                    <a href="#" class="add-btn"> Cancel</a>
                    <a href="#" class="rem-btn"> Save</a>
                </div> -->
        </div>
      </div>
      <!-- Email campaign -->
    </form>

    <div
      class="modal fade"
      id="exampleModal33"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-xl"
        :class="filteredCustomers.length > 10 ? '' : 'modal-dialog-centered'"
        role="document"
      >
        <div class="modal-content preview-modal">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              List Generator Preview
              <div>
                <p>Total Users: {{ totalUsers }}</p>
              </div>
            </h5>
            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>

          <!-- <div class="download-links">
                    <ul>
                        <li>Print</li>
                        <li>
                            <a href="#" v-on:click="downloadIt('print')" ><img class="img-fluid print-icon" src="../../assets/img/print.svg" alt=""></a>
                        </li>
                        <li>Download</li>
                        <li>
                            <a href="#" v-on:click="downloadIt('img')"><img class="img-fluid" src="../../assets/img/pdf.svg" alt=""></a>
                        </li>
                        <li>
                            <a href="#" v-on:click="downloadIt('pdf')"><img class="img-fluid" src="../../assets/img/pdfp.svg" alt=""></a>

                        </li>
                    </ul>
                </div> -->
          <div class="modal-body">
            <!-- leads-table-area  -->
            <div
              class="leads-table-area addcustomer-table addcampaigm-table"
              id="download_area"
              v-show="loaded"
            >
              <table class="display" id="add_campaign_list_table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Email Address</th>
                    <th>Phone</th>
                    <th>Address</th>
                    <!-- <th>Sales Type</th>
                                    <th>Source</th>
                                    <th>Status</th>
                                    <th>Assigned</th>
                                    <th>Email Address</th>
                                    <th>Address</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="cdata in filteredCustomers" :key="cdata.c_id">
                    <td>#{{ cdata.c_id }}</td>
                    <td>{{ cdata.c_first_name }} {{ cdata.c_last_name }}</td>
                    <td>{{ cdata.c_email }}</td>
                    <td>{{ cdata.c_phone }}</td>
                    <td>
                      {{ cdata.c_city }} {{ cdata.c_state }} {{ cdata.c_zip }}
                    </td>
                  </tr>
                  <!-- <tr>
                                    <td>1</td>
                                    <td>Mehdi Hasan</td>
                                    <td>Purchase</td>
                                    <td>Internet</td>
                                    <td>Sales Rep</td>
                                    <td><a class="solds" href="#">Active</a></td>
                                    <td><img class="img-fluid chek-cl" src="../../assets/img/checks.svg" alt=""></td>
                                    <td>Inglewood, Maine 98380</td>
                                </tr> -->
                </tbody>
              </table>
            </div>
            <div v-show="!loaded" class="text-center">
              <span class="spinner-border spinner-border-sm"></span> Loading...
            </div>
            <!-- leads-table-area  -->
          </div>
        </div>
      </div>
    </div>

    <div id="print_area" class="print_area">
      <img :src="print_src" style="max-width: 100%" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
import VueDaval from "vue-daval";
// import "datatables.net-buttons-bs";
import "datatables.net";
import "datatables.net-responsive";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";
import "datatables.net-buttons";
import "datatables.net-buttons/js/buttons.html5.min";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";

import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

import MultiSelect from "../helpers/MultiSelect";
import moment from "moment";

import * as htmlToImage from "html-to-image";
import jsPDF from "jspdf";

export default {
  name: "AddCampaignsList",
  components: { MultiSelect, DateRangePicker, VueSlider },
  mixins: [VueDaval],
  data() {
    return {
      model: [],
      make: [],
      totalUsers: "",
      pdf: require("../../assets/img/pdfp.svg"),
      csv: require("../../assets/img/csv.svg"),
      print: require("../../assets/img/print.svg"),
      cl_: {
        contact: "",
        action: "add",
        id: 0,
        title: "",
        user_cate: "customers",
        filter_type: "",
        duration: "all",
        customDate: {},
        customDateFrom: "",
        customDateTo: "",
        city: "",
        state: "",
        zip: [],
        source: "",
        status: 0,
        agent: "",
        lead_created_duration: "all",
        lead_created_custom_date: {},
        lead_created_from: "",
        lead_created_to: "",
        make: "",
        model: "",
        mileage: [0, 300000],
        year: [1950, 2023],
        sales_type: "",
        condition: "",
        va_status: "",
        purchase_duration: "all",
        purchase_date: {},
        purchase_date_from: "",
        purchase_date_to: "",
      },
      LSources: [],
      allZipCodes: [],
      allEmployees: [],
      yearOptions: {
        dotSize: 16,
        height: 2,
        tooltip: "none",
        min: 1950,
        max: 2023,
      },
      mileageOptions: {
        dotSize: 16,
        height: 2,
        tooltip: "none",
        min: 0,
        max: 300000,
      },
      filteredCustomers: [],
      loaded: false,
      btnLoader: false,
      print_src: "",
      //dataTable : ''
    };
  },
  vdRules: {
    cl_: {
      title: { required: true },
      user_cate: { required: true },
    },
  },
  created() {
    this.getCustomersZipCodes();
    this.leadSources();
    this.getAllEmployees();
    this.getInventory();
  },
  methods: {
    getInventory() {
      axios
        .get(
          "https://autolinkme.com/webservices/crm/inventory?id=" +
            this.$storage.get("auth").user.dealer_autolink_id +
            "&limit=1000&offset=0&status=active"
        )
        .then((res) => {
          if (res.data.status) {
            res.data.data.forEach((data) => {
              if (
                data.make != "" &&
                this.make.some((code) => code.make === data.make) == false
              ) {
                this.make.push({ make: data.make });
              }
              if (
                data.model != "" &&
                this.model.some((code) => code.model === data.model) == false
              ) {
                this.model.push({ model: data.model });
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    downloadIt(type = "") {
      if (type == "img") {
        htmlToImage
          .toJpeg(document.getElementById("download_area"))
          .then(function (dataUrl) {
            var link = document.createElement("a");
            link.download = Date.now() + ".jpeg";
            link.href = dataUrl;
            link.click();
          });
      } else if (type == "pdf") {
        const doc = new jsPDF();
        htmlToImage
          .toJpeg(document.getElementById("download_area"))
          .then(function (dataUrl) {
            doc.addImage(dataUrl, "JPEG", 10, 10, 190, 140);
            doc.save();
          });
      } else if (type == "print") {
        let vm = this;
        htmlToImage
          .toJpeg(document.getElementById("download_area"))
          .then(function (dataUrl) {
            //console.log(dataUrl)
            vm.print_src = dataUrl;
            setTimeout(() => {
              vm.$htmlToPaper("print_area");
            }, 500);
          });
      }
    },
    addCampaignList() {
      this.$vd.cl_.$validate().then(() => {
        console.log(this.cl_);

        if (this.cl_.duration == "custom") {
          var startDate = this.cl_.customDate.startDate;
          var endDate = this.cl_.customDate.endDate;
          this.cl_.customDateFrom = moment(new Date(startDate)).format(
            "YYYY-MM-DD"
          );
          this.cl_.customDateTo = moment(new Date(endDate)).format(
            "YYYY-MM-DD"
          );
        }

        if (this.cl_.lead_created_duration == "custom") {
          var lc_startDate = this.cl_.lead_created_custom_date.startDate;
          var lc_endDate = this.cl_.lead_created_custom_date.endDate;
          this.cl_.lead_created_from = moment(new Date(lc_startDate)).format(
            "YYYY-MM-DD"
          );
          this.cl_.lead_created_to = moment(new Date(lc_endDate)).format(
            "YYYY-MM-DD"
          );
        }

        if (this.cl_.purchase_duration == "custom") {
          var p_startDate = this.cl_.purchase_date.startDate;
          var p_endDate = this.cl_.purchase_date.endDate;
          this.cl_.purchase_date_from = moment(new Date(p_startDate)).format(
            "YYYY-MM-DD"
          );
          this.cl_.purchase_date_to = moment(new Date(p_endDate)).format(
            "YYYY-MM-DD"
          );
        }
        this.btnLoader = true;
        axios
          .post("add_campaign_list", this.cl_, {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          })
          .then((res) => {
            if (res.data.success) {
              // this.$router.push({ name: "campaignsList" });
              //$("#addCampaignListModal").modal('hide');
              //$('#campaigns_table').dataTable().fnDraw();
              window.location.href = "/campaigns-list";
              this.btnLoader = false;
            }
          })
          .catch((err) => {
            console.log(err);
            this.btnLoader = false;
          });
      });
    },
    get_campaign_list_users() {
      this.loaded = false;
      if (this.cl_.duration == "custom") {
        var startDate = this.cl_.customDate.startDate;
        var endDate = this.cl_.customDate.endDate;
        this.cl_.customDateFrom = moment(new Date(startDate)).format(
          "YYYY-MM-DD"
        );
        this.cl_.customDateTo = moment(new Date(endDate)).format("YYYY-MM-DD");
        console.log(this.cl_.lead_created_custom_date.startDate);
      }
      if (this.cl_.lead_created_duration == "custom") {
        this.cl_.lead_created_from = moment(
          new Date(this.cl_.lead_created_custom_date.startDate)
        ).format("YYYY-MM-DD");
        this.cl_.lead_created_to = moment(
          new Date(this.cl_.lead_created_custom_date.endDate)
        ).format("YYYY-MM-DD");
      }
      $("#add_campaign_list_table").dataTable().fnDestroy();
      axios
        .post("campaign_list_users", this.cl_, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          const data = res.data;
          if (data.success) {
            this.filteredCustomers = data.success;
            this.totalUsers = data.count;
            //this.loaded = true
            setTimeout(() => {
              $("#add_campaign_list_table")
                .addClass("nowrap")
                .dataTable({
                  dom: "Blfrtip",
                  buttons: [
                    {
                      extend: "pdfHtml5",
                      text:
                        '<img class="img-fluid" src="' + this.pdf + '" alt="">',
                      className: "btn_pdf",
                      titleAttr: "PDF",
                    },

                    {
                      extend: "csvHtml5",
                      text:
                        '<img style="width: 66px;" class="img-fluid" src="' +
                        this.csv +
                        '" alt="">',
                      titleAttr: "CSV",
                      className: "btn_csv",
                    },

                    {
                      extend: "print",
                      text:
                        '<img style="width: 42px;" class="img-fluid" src="' +
                        this.print +
                        '" alt="">',
                      title: "",
                      className: "btn_print",
                    },
                  ],
                  responsive: true,
                  fixedHeader: true,
                  paging: false,
                  ordering: false,
                  info: false,
                });
              this.loaded = true;
            }, 1000);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCustomersZipCodes() {
      axios
        .get("get_customers_zipcodes", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          const data = res.data;
          if (data.success) {
            if (data.success.length > 0) {
              var dd = [];
              $.each(data.success, function (key, value) {
                dd.push({ id: value.c_zip, text: value.c_zip });
              });
              this.allZipCodes = dd;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    selectedFilter(e, name) {
      $("input[name='" + name + "']")
        .parent()
        .removeClass("currnt");
      if (e.target.checked) {
        $(e.target.parentNode).addClass("currnt");
      } else {
        $(e.target.parentNode).removeClass("currnt");
      }
    },
    leadSources() {
      axios
        .get("lead_sources")
        .then((res) => {
          if (res.data.success) {
            this.LSources = res.data.success;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getAllEmployees() {
      axios
        .get("all_employees/2", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          const data = res.data;
          if (data.success) {
            if (data.success.length > 0) {
              var dd = [];
              $.each(data.success, function (key, value) {
                dd.push({ id: value.id, text: value.name });
              });
              this.allEmployees = dd;
              //console.log(this.allEmployees);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
@import "../../assets/css/dataTables.css";
.con-company .checkmark {
  position: absolute;
  top: 9px;
  left: 10px;
  height: 14px;
  width: 14px;
  background-color: #fff;
  border-radius: 7px;
}
.con-company .checkmark:after {
  left: 4px;
  top: 1px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.add-campaign-list-btn {
  position: absolute;
  right: 12px;
  top: 13px;
  z-index: 1;
}
.modal-dialog {
  max-width: 919px;
}
.campaigns-list-area {
  position: relative;
}
.vue-daterange-picker {
  position: static;
  display: block;
}
</style>
<style type="text/css">
.show-options {
  top: 34px !important;
}
.vue-slider-dot-handle {
  background-color: #3151a1 !important;
  box-shadow: none !important;
}
.vue-slider-process {
  background-color: #3151a1 !important;
}
.dataTables_wrapper .dataTables_filter {
  left: 454px;
  position: relative;
}
.campignlist .daterangepicker.show-calendar {
  display: block;
  top: auto;
  bottom: 100%;
}
</style>

